export type Pulse = {
    type: 'OOO' | 'PULSE' | 'NO_INFO' | 'SKIP' | 'FUTURE';
    text: string;
    date: string;
    };

export type PulseData = {
    [date: string]: Pulse;
}

export type CompanyPulseData = {
    user: any,
    pulses: PulseData,
}

export type BaseUserData = {
    id: number;
    name: string;
}

export type CheckinData = {
    id: number;
    leader: BaseUserData;
    happiness: number;
    productivity: number;
    passion: number;
    comment: string;
}

export type Person = {
    id: number;
    name: string;
    email: string;
    username: string;
}

export type CoreValue = {
    key: string;
    name: string;
}

export type Shoutout = {
    id: number;
    from: Person;
    receivers: number[];
    coreValues: string[];
    why: string;
    delivery: string;
}

export enum OfficeLocation {
    US = 'US',
    UA = 'UA',
    PL = 'PL',
    DE = 'DE',
    TH = 'TH',
    PT = 'PT'
}
export type Holiday = {
    id: number;
    year: number;
    date: string;
    name: string;
    location: OfficeLocation;
}
