<script setup>
import useTimeoffHelper from "@/composables/useTimeoffHelper";
import {computed} from "vue";

const to = useTimeoffHelper();

const {value, color, label} = defineProps(['value', 'color', 'label', 'hideLabel'])

const colorValue = computed(() => color || to.byValue(value).color)
const labelValue = computed(() => label || to.byValue(value).label)

</script>

<template>
  <div class="flex flex-col">
    <div class="flex gap-2 items-center">
      <i class="pi pi-circle-fill" :style="{color: colorValue}"
         v-tooltip.top="hideLabel? labelValue : '' "/>
      <span v-if="!hideLabel">{{ labelValue }}</span>
    </div>
  </div>
</template>
