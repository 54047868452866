import {DateTime} from "luxon";
import {useHolidaysStore} from "@/stores/holidays.store.ts";
import {Holiday} from "@/types/DataTypes.ts";

function getDate(dateStr: string | Date) {
    return dateStr instanceof Date ?
        DateTime.fromJSDate(dateStr).startOf('day'):
        DateTime.fromISO(dateStr).startOf('day');
}

function getStart(timeoff: any) {
    return getDate(timeoff.start || timeoff.range.start);
}

function getEnd(timeoff: any) {
    return getDate(timeoff.end || timeoff.range.end);
}


export default function useTimeoffHelper() {
    const holidaysStore = useHolidaysStore();
    return {
        singleDay(timeoff: any) {
            return getStart(timeoff).hasSame(getEnd(timeoff), 'day');
        },

        format(date: string | Date) {
            return date ? getDate(date).toFormat('MMM dd') : "";
        },

        findByDate(list: any[], date: Date) {
            const luxonDate = DateTime.fromJSDate(date);
            return list.find(to => {
                // Check if the clicked date is within the range of the attribute
                return luxonDate >= getStart(to) && luxonDate <= getEnd(to);
            });
        },

        countAllWorkdays(timeoffList: any[], type: null | string = null) {
            const list = type ? timeoffList.filter(to => to.type == type) : timeoffList;
            return list.reduce((acc, to) => acc + this.countWorkdays(to), 0);
        },

        countWorkdays(timeoff: any) {
            let count = 0;
            let startDate = getStart(timeoff);
            const endDate = getEnd(timeoff);
            if (timeoff.partial) {
                return timeoff.partial / 8;
            }
            const holidays = holidaysStore.forLocation(timeoff.user.location).map((h: Holiday) => h.date);

            while (startDate <= endDate) {
                const isoDate = startDate.toISODate()!;
                if (!holidays.includes(isoDate) && startDate.weekday < 6 && startDate.weekday >= 1) {
                    count++;
                }
                startDate = startDate.plus({days: 1});
            }
            return count;
        },

        byValue(type: any) {
            for (const t of this.types()) {
                if (t.value == type) {
                    return t;
                }
            }
            return null;
        },

        description(type: any) {
            switch (type) {
                case 'vacation':
                    return 'Time planned to be away from work to rest, recover, travel, or have other experiences';
                case 'personal':
                    return 'Short absence to handle personal needs, such as medical appointments, car repairs, sick children, etc. This can be planned or unplanned.';
                case 'sick':
                    return 'When you are feeling ill and need time to recover.';
                case 'parental':
                    return 'Requires advance approval with the company as there are regional/state laws that may apply.';
                case 'bereavement':
                    return 'Time for grieving or handling affairs related to a death of a loved one.';
                default:
                    return 'Unknown';
            }
        },
        types() {
            return [
                {label: 'Vacation or Holiday Time', value: 'vacation', color: 'green'},
                {label: 'Personal Time', value: 'personal', color: 'blue'},
                {label: 'Sick Leave', value: 'sick', color: 'orange'},
                {label: 'Parental Leave (Paternity / Maternity)', value: 'parental', color: 'purple'},
                {label: 'Bereavement', value: 'bereavement', color: 'gray'},
                {label: 'Public Holiday', value: 'holiday', color: 'darkred'}
            ]
        },
        editableTypes() {
            return this.types().filter(t => t.value != 'holiday');
        }
    }
}
