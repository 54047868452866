<script setup lang="ts">
import useTimeoffHelper from "@/composables/useTimeoffHelper.ts";
import Panel from "primevue/panel";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import {DatePicker} from "v-calendar";
import Select from "primevue/select";
import TimeoffType from "@/components/timeoff/TimeoffType.vue";
import { isDark } from "@/composables/useDark";
import Debug from "@/components/Debug.vue";
import {DateTime} from "luxon";
import {computed, ref} from "vue";
import useAppUser from "@/composables/useAppUser.ts";

const appUser = useAppUser()
type TTimeoff = {
  range: { start: Date, end: Date },
  type: string,
  start?: string | null,
  end?: string | null,
  partial?: number,
  comment?: string
  user: any
}

defineProps(['existing'])
const emits = defineEmits(['save', 'close'])

const to = useTimeoffHelper();
const timeOff = ref<TTimeoff>({
  range: {} as any,
  type: 'vacation',
  user: appUser,
})

const partialOptions = [
  {
    label: 'Full day (8hrs)',
    value: null
  },
  {
    label: '6 hours',
    value: 6
  },
  {
    label: 'Half day (4hrs)',
    value: 4
  },
  {
    label: '2 hours',
    value: 2
  }
]

async function saveTimeoff() {
  timeOff.value.start = DateTime.fromJSDate(timeOff.value.range.start).toISODate();
  timeOff.value.end = DateTime.fromJSDate(timeOff.value.range.end).toISODate();

  emits('save', timeOff.value)
}

function workingDaysText(timeoff: TTimeoff) {
  const workdays = to.countWorkdays(timeoff);
  return workdays + ' working day' + (workdays == 1 ? '' : 's');
}

const singleDay = computed(() => to.singleDay(timeOff.value))
const editingColor = computed(() => to.byValue(timeOff.value.type)?.color)
const saveable = computed(() => timeOff.value.range.start && timeOff.value.range.end)
const selectedDates = computed(() => {
  if (!timeOff.value.range.start)
    return "";
  let value = to.format(timeOff.value.range.start);

  if (!singleDay.value) {
    value += " - " + to.format(timeOff.value.range.end)
  }
  return value;
})

const description = computed(() => to.description(timeOff.value.type))

</script>
<template>
    <div class="flex gap-2 p-4">
      <div>
        <DatePicker v-model.range="timeOff.range" :color="editingColor"
                    :attributes="existing"
                    transparent
                    :is-dark="isDark()"

        />
      </div>
      <Panel :header="selectedDates || '<- Pick dates'">
      <div class="flex flex-col justify-around gap-2">
        <Select v-model="timeOff.type" :options="to.editableTypes()" option-value="value" class="w-full">
          <template #value="{value}">
            <TimeoffType :value="value" :key="value"/>
          </template>
          <template #option="{option}">
            <TimeoffType :value="option.value"/>
          </template>
        </Select>
        <span class="text-xs text-muted-color w-[25rem]">{{ description }}</span>
        <Select v-if="singleDay" v-model="timeOff.partial" :options="partialOptions"
                  option-value="value"
                  option-label="label" placeholder="Full day (8hrs)" class="w-full"/>
        <InputText v-else disabled :value="workingDaysText(timeOff)" class="w-full"/>
        <Textarea v-model="timeOff.comment" placeholder="leave a comment" class="w-full"/>
        <div >
          <div class="flex justify-between gap-2">

            <Button @click="saveTimeoff" :disabled="!saveable"> Save</Button>
            <Button @click="$emit('close')" severity="secondary" outlined>Cancel</Button>
          </div>

        </div>
      </div>
      </Panel>
    </div>
  <Debug :data="timeOff"/>


</template>
