<script setup lang="ts">

import {onMounted, Ref, ref} from "vue";
import pulseBackend from "@/backend/pulse.backend";
import WeekPicker from "@/components/WeekPicker.vue";
import {DateTime} from "luxon";
import {useRoute, useRouter} from "vue-router";
import Debug from "@/components/Debug.vue";
import DataTable, {DataTableExpandedRows, DataTableRowSelectEvent} from "primevue/datatable";
import Column from "primevue/column";
import HOSAvatar from "@/components/hOSAvatar.vue";
import Weekdays from "@/components/Weekdays.vue";
import PulseCard from "@/components/pulse/PulseCard.vue";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import InputText from "primevue/inputtext";
import {FilterMatchMode} from "@primevue/core/api";
import PulseWeekStats from "@/components/pulse/PulseWeekStats.vue";
import {CompanyPulseData} from "@/types/DataTypes.ts";

const router = useRouter();
const route = useRoute();

const loading = ref(false)
const weekStart: Ref<DateTime> = ref<DateTime>(initWeek())
const pulses = ref<CompanyPulseData[] | null>()
const filters = ref({global: {value: null, matchMode: FilterMatchMode.CONTAINS}})
const expandedRows = ref<DataTableExpandedRows>({})
const statsKey = ref(0)

function initWeek() {
  const baseDate = route.query.week ? DateTime.fromISO(route.query.week as string) : DateTime.now()
  return baseDate.startOf('day').startOf('week')
}

async function loadPulses() {
  loading.value = true
  const isoDate = weekStart.value.toISODate();
  pulses.value = await pulseBackend.week(isoDate!)();
  statsKey.value++;
  loading.value = false
}


onMounted(async () => {
  const weekOf = route.query.week ? DateTime.fromISO(route.query.week as string) : DateTime.now().startOf('day').startOf('week')
  if (weekOf.toISODate() !== weekStart.value.toISODate()) {
    weekStart.value = weekOf
    pulses.value = null
  }

  if (!pulses.value) {
    await loadPulses()
  }
})

async function switchWeek() {
  await router.push({query: {week: weekStart.value.toISODate()}})
  await loadPulses();
}

function onRowSelect(event: DataTableRowSelectEvent) {
  const userId: string = event.data.user.id
  if (expandedRows.value[userId])
    delete expandedRows.value[userId]
  else
    expandedRows.value[userId] = true
}

/* Stop event propagation so that onRowSelect logic does not conflict with native expand/collapse */
function doNotPropagate(event: DataTableRowSelectEvent) {
  event.originalEvent.stopPropagation()
}

</script>

<template>
  <div>
    <div>

      <DataTable
          :value="pulses"
          :globalFilterFields="['user.name', 'user.team']" v-model:filters="filters"
          v-model:expanded-rows="expandedRows" dataKey="user.id"
          :loading="loading"
          selection-mode="single"
          @row-select="onRowSelect"
          @row-expand="doNotPropagate"
          @row-collapse="doNotPropagate"
          sortField="user.team" :sort-order="1"
      >
        <template #header>
          <div class="flex items-center gap-2">
            <WeekPicker v-model="weekStart" @change="switchWeek"/>
            <IconField>
              <InputIcon><i class="pi pi-search"/></InputIcon>
              <InputText v-model="filters['global'].value" placeholder="Filter Team & Names"/>
            </IconField>
          </div>
        </template>
        <Column expander style="width: 1rem"/>
        <Column style="width: 1rem">
          <template #body="{data}">
            <HOSAvatar :user="data.user"/>
          </template>
        </Column>
        <Column header="" field="user.name"
                style="white-space: nowrap; padding-right: 2rem">
          <template #body="{data}">
            <span class="font-bold">{{data.user.name}}</span>
          </template>
        </Column>
        <Column header="Team" field="user.team"
                style="white-space: nowrap; padding-right: 2rem"></Column>
        <Column style="width: 100%">
          <template #header>
            <PulseWeekStats :pulses="pulses" v-if="pulses" :key="statsKey"/>
          </template>
          <template #body="{data}">
            <Weekdays :weekdays="data.pulses" />
          </template>
        </Column>
        <template #expansion="{data}">
          <div class="grid grid-cols-5">
            <PulseCard :pulse="pulse" v-for="pulse in data.pulses"
                       :key="pulse.date"
                       class="hover:z-10 hover:drop-shadow-xl"/>
          </div>
        </template>
      </DataTable>
      <Debug :data="pulses"/>
    </div>
  </div>
</template>
