<script setup>

import AppPage from "@/components/AppPage.vue";

import PulseWeeklyView from "@/components/pulse/PulseWeeklyView.vue";
import RouteTab from "@/components/RouteTab.vue";
import CompanyPulseWeekly from "@/components/pulse/CompanyPulseWeekly.vue";
import CompanyPulseDaily from "@/components/pulse/CompanyPulseDaily.vue";
import {useAuthStore} from "@/stores/index.js";

const authStore = useAuthStore()

const tabs = []
if (authStore.user.pulse_enabled) {
  tabs.push({label: 'My Pulse', to: '/pulse', icon: 'pi pi-wave-pulse'})
}
if (authStore.canSeePulse) {
  tabs.push({label: 'hOS Weekly', to: '/pulse/weekly', icon: 'pi pi-calendar'})
  tabs.push({label: 'hOS Daily', to: '/pulse/daily', icon: 'pi pi-calendar-clock'})
}
</script>

<template>
  <AppPage title="Pulse" :loaded="true" :tabs="tabs">
    <RouteTab tabRoute="/pulse">
      <PulseWeeklyView :user-id="authStore.user.id" />
    </RouteTab>
    <RouteTab tabRoute="/pulse/weekly">
      <CompanyPulseWeekly />
    </RouteTab>
    <RouteTab tabRoute="/pulse/daily">
      <CompanyPulseDaily />
    </RouteTab>

  </AppPage>
</template>
