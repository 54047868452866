<script setup>
import {onMounted, ref} from "vue";
import useAppToast from "@/composables/useAppToast.js";
import {adminBackend} from "@/backend/admin.backend";
import Button from "primevue/button";
import Debug from "@/components/Debug.vue";

const config = ref();
const loading = ref(true);
const saving = ref(false);
const toast = useAppToast();

onMounted(async () => {
  config.value = await adminBackend.appConfig();
  loading.value = false;
})

const save = async () => {
  saving.value = true;
  await adminBackend.saveAppConfig(config.value);
  saving.value = false;
  toast.success("Saved!")
}
</script>

<template>

  <div class="flex flex-col gap-2" v-if="config">
    <Textarea v-model="config.pulse.prompts" id="pulse-prompts" class="w-full" rows="15"/>
    <div class="flex justify-end">
      <Button class="btn btn-primary" @click="save" :loading="saving">Save</Button>
    </div>
    <Debug :data="config"/>
  </div>
</template>
