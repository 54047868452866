<script setup lang="ts">
import {computed, ref} from "vue";
import {useHolidaysStore} from "@/stores/holidays.store.ts";
import Debug from "@/components/Debug.vue";
import {DateTime} from "luxon";
import useAppToast from "@/composables/useAppToast";

const DATE_FORMATS = ['MMMM d', 'MMMM d, yyyy'];
const holidaysStore = useHolidaysStore();
const toast = useAppToast()

const {year} = defineProps(['year'])
const emit = defineEmits(['done'])

const location = ref();
const holidaysInput = ref()
const parsedHolidays = computed(() => {
  if (!holidaysInput.value) return [];
  return holidaysInput.value.trim().split('\n').map((line: any) => {
    const [name, dateStr] = line.split(':').map((part: any) => part.trim());

    let date = parseDate(dateStr);
    return {name, date, location: location.value, year};
  });
});

function parseDate(dateStr: string) {
  for (const format of DATE_FORMATS) {
    const date = DateTime.fromFormat(dateStr, format, {zone: 'utc'});
    if (date.isValid) return date.toJSDate();
  }
  return null;
}

function formatDate(date: Date) {
  return DateTime.fromJSDate(date).toLocaleString({
    ...DateTime.DATE_MED_WITH_WEEKDAY,
    year: undefined
  });
}

function isDateValid(date: Date) {
  return DateTime.fromJSDate(date).isValid;
}

const saveable = computed(() => {
  if (!parsedHolidays.value || parsedHolidays.value.length === 0) return false;
  if (!location.value) return false;
  for (const holiday of parsedHolidays.value) {
    if (!isDateValid(holiday.date)) return false;
  }
  return true;
});

async function addHolidays() {
  try {
    await holidaysStore.addHolidays(parsedHolidays.value);
    holidaysInput.value = '';
    emit('done');
  } catch (e: any) {
    toast.error(e.message);
  }

}
</script>

<template>
  <div class="flex flex-col gap-2 w-[60rem]">
    <div class="flex flex-row items-center gap-2">
      <span>Year: {{ year }}</span>
    </div>
    <span class="text-muted-color text-xs">One holiday per line, format: name: date, e.g. 'New Year: January 1'</span>
    <div class="grid grid-cols-2 gap-4">
      <Textarea v-model="holidaysInput" :rows="10"/>
      <div class="flex flex-col gap-2 border p-1 rounded">
        <div class="flex flex-row gap-2" v-for="holiday in parsedHolidays">
          <span class="w-[8rem]" :class="{'text-red-500': !isDateValid(holiday.date)}">
            {{ formatDate(holiday.date) }}
          </span>
          <span>{{ holiday.name }}</span>
        </div>
      </div>
    </div>
    <div class="flex justify-end items-center gap-2">
      <label>Location:</label>
      <Select :options="holidaysStore.locations" v-model="location" label="Location"/>
      <Button label="Add" :disabled="!saveable" class="ml-4" @click="addHolidays"/>
    </div>
    <Debug :data="{saveable, parsedHolidays}" expanded/>
  </div>
</template>
