import {useAuthStore} from '@/stores';

const API_URL = import.meta.env.VITE_API_URL + "/api"

export const backend = {

    getLeadCheckin: (weekOf) => request('GET', `/checkins/lead/${weekOf}`)(),
    saveLeadCheckin: request('POST', '/checkins/lead'),
    saveSelfCheckin: request('POST', '/checkins/self'),
    getSelfCheckin: (weekOf) => request('GET', `/checkins/self/${weekOf}`)(),
    acknowledgeCheckin: (id) => request('POST', `/checkins/${id}/acknowledge`),
    myretros: request('GET', '/retros'),
    getRetro: (id, weekOf) => request('GET', `/retros/${id}/${weekOf}`)(),
    // SKS
    confidants: request('GET', '/sks/confidants'),
    getAllSKS: request('GET', '/sks'),
    saveSKS: request('POST', '/sks'),
    getSKSDetails: (slug) => request('GET', `/sks/${slug}`),
    sendSKSPost: (slug) => request('POST', `/sks-post/${slug}`),
    getNameForSKS: (slug) => request('GET', `/sks-post/${slug}`),
    // Admin
    users: request('GET', '/admin/users'),
    usersShort: request('GET', '/admin/users/short'),
    createUsers: request('POST', '/admin/users'),
    editUser: request('PUT', '/admin/users'),
    deleteUser: request('DELETE', '/admin/users'),
    setTeam: request('POST', '/admin/users/team'),
    groups: request('GET', '/admin/groups'),
    saveGroup: request('POST', '/admin/groups'),
    addPeopleToGroup: request('POST', '/admin/groups/people'),
    removePeopleFromGroup: request('DELETE', '/admin/groups/people'),
    deleteGroup: request('DELETE', '/admin/groups'),
    parsePastCheckins: submitFile('POST', '/admin/import/parse'),
    importPastCheckins: request('POST', '/admin/import'),

    getSlackChannels: request('GET', '/admin/slack'),
    slack: request('POST', '/admin/slack'), // TODO: Remove

    sendLink: request('POST', '/auth'),
    refreshToken: request('PUT', '/auth'),

    // wall of fame
    getWOF: request('GET', '/wof'),
    getBadges: request('GET', '/admin/badges'),
    saveBadge: request('POST', '/admin/badges'),
    uploadPicture: (id, file) => submitFile('PUT', `/admin/badges/${id}`)(file),
    addUsersToBadge: request('POST', '/admin/badges/users'),
    removeUsersFromBadge: request('DELETE', '/admin/badges/users'),
    badge_url: (id) => `${API_URL}/badge/${id}`,
    avatar_url: (id) => `${API_URL}/avatar/${id}`,

};
function submitFile(method, url) {
    return (file) => {
        const requestOptions = {
            method,
            headers: authHeader()
        };
        console.log("Sending file", file);
        let data = new FormData();
        data.append("file", file);
        console.log("Form data: ", data);
        // requestOptions.headers['Content-Type'] = `multipart/form-data`;
        requestOptions.body = data;
        // console.log("request headers", requestOptions);
        return fetch(API_URL+ url, requestOptions).then(handleResponse);
    }

}
export function request(method, url) {
    return (body = null) => {
        const requestOptions = {
            method,
            headers: authHeader()
        };
        if (body) {
            requestOptions.headers['Content-Type'] = 'application/json';
            requestOptions.body = JSON.stringify(body);
        }
        // console.log("JS request headers", requestOptions);
        return fetch(API_URL+ url, requestOptions).then(handleResponse);
    }
}

// helper functions
function authHeader() {
    // return auth header with jwt if user is logged in and request is to the api url
    const { jwt } = useAuthStore();
    const isLoggedIn = !!jwt;
    if (isLoggedIn) {
        return { Authorization: `Bearer ${jwt}` };
    } else {
        return {};
    }
}

function handleResponse(response) {
    // console.log("Handling the response");
    return response.text().then(text => {
        // console.log("is response ok?", text);
        if (!response.ok) {
            if (response.status == 401) {
                // auto logout if 401 Unauthorized response returned from api
                useAuthStore().logout()
            }
            console.log("Rejecting the response");
            return Promise.reject(response.statusText);
        }
        // console.log(JSON.parse(text));
        return text && JSON.parse(text);
    });
}
