<script setup lang="ts">
import {useHolidaysStore} from "@/stores/holidays.store.ts";
import {computed, ref} from "vue";
import HolidaysEditor from "@/components/admin/HolidaysEditor.vue";
import useAppUser from "@/composables/useAppUser.ts";
import {humanDateEEEMMMdd} from "@/helpers/date.helper";


const holidaysStore = useHolidaysStore();
const selectedYear = ref(new Date().getFullYear());

const holidaysMap = computed(() => {
  return holidaysStore.holidays?.filter(holiday => holiday.year === selectedYear.value).reduce((acc, holiday) => {
    if (!acc[holiday.location]) {
      acc[holiday.location] = [];
    }
    acc[holiday.location].push(holiday);
    return acc;
  }, {} as Record<string, any[]>);
})

const holidaysKeys = computed(() => Object.keys(holidaysMap.value) )
const appUser = useAppUser()

const years = computed(() => {
  const years = new Set();
  const currentYear = new Date().getFullYear();
  years.add(currentYear);
  years.add(currentYear + 1);
  holidaysStore.holidays?.forEach(holiday => {
    years.add(holiday.year);
  });
  return Array.from(years).sort((a: any, b: any) => b - a);
});

const editor = ref();

const openEditor = (e: any) => {
  editor.value.show(e);
}

const closeEditor = () => {
  editor.value.hide();
}
</script>

<template>
  <div v-if="holidaysStore.holidays">
    <div class="flex flex-row gap-2">
      <Select :options="years" v-model="selectedYear" label="Year"/>
      <Button label="Add holidays" icon="pi pi-plus" @click="openEditor" v-if="appUser.is_admin"/>
    </div>

    <div class="flex flex-wrap gap-2" :key="selectedYear">
      <Fieldset v-for="location in holidaysKeys" :key="location" :legend="location">
        <div class="flex flex-row gap-4 mb-4" v-for="holiday in holidaysMap[location]" :key="holiday.id">
          <span class="w-[8rem]">
            <Tag :value="humanDateEEEMMMdd(holiday.date)" class="w-full"  />
          </span>
          <span>{{ holiday.name }}</span>
        </div>
      </Fieldset>
    </div>

    <Popover ref="editor">
      <HolidaysEditor :year="selectedYear" @done="closeEditor"/>
    </Popover>
  </div>
</template>
