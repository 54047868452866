<script setup>

import {ref} from "vue";
import TimeoffType from "@/components/timeoff/TimeoffType.vue";

const info = ref()

const legend = {
  'vacation': 'Vacation is for time planned to be away from work to rest, recover, travel, or have other experiences.',
  'personal': 'Personal time is for a short absence to handle personal needs, such as medical appointments, car repairs, sick children, etc. This can be planned or unplanned.',
  'sick': 'Use this time when you are feeling ill and need time to recover.',
  'parental': 'Requires advance approval with the company as there are regional/state laws that may apply.',
  'bereavement': 'Bereavement is for time for grieving for handling affairs related to a death of a loved one.'
}
function toggleInfo(e) {
  info.value.toggle(e)
}

</script>

<template>
  <div class="flex items-center cursor-pointer gap-1  text-muted-color">
    <a href="https://docs.google.com/document/d/1xV-qn9v4eHTL_0HtQBZjDZILP3UTyeZr-NF32qbFkyA/edit?tab=t.0" target="_blank">
      PTO Policy 2025
      <i class="pi pi-external-link mr-1"/>
    </a>
    <Popover ref="info"  class="w-[25rem] p-2">
      <ul>
        <li v-for="type in Object.keys(legend)" class="mb-3">
          <TimeoffType :value="type" class="font-bold"/>
          <span class="text-muted-color">
            {{ legend[type] }}
          </span>
        </li>
      </ul>
    </Popover>
  </div>
</template>
