<script setup>
import {computed, onMounted, ref, watch} from 'vue';
import TimeOffStats from "@/components/timeoff/TimeOffStats.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import useTimeoffHelper from "@/composables/useTimeoffHelper";
import {Calendar} from "v-calendar";
import Panel from "primevue/panel";
import Button from "primevue/button";
import {DateTime} from "luxon";
import InputText from "primevue/inputtext";
import TimeoffStatusLabel from "@/components/timeoff/TimeoffStatusLabel.vue";
import MultiSelect from "primevue/multiselect";
import Skeleton from "primevue/skeleton";
import Select from "primevue/select";
import Popover from "primevue/popover";
import TimeoffType from "@/components/timeoff/TimeoffType.vue";
import TimeoffLegend from "@/components/timeoff/TimeoffLegend.vue";
import {darkKey, isDark} from "@/composables/useDark";
import Debug from "@/components/Debug.vue";
import {useHolidaysStore} from "@/stores/holidays.store";

const COMMENT_CUTOUT = 20;

const props = defineProps(['timeoffList', 'hideName', 'hideComment', 'exportable', 'location']);
const emit = defineEmits(['mounted']);

const to = useTimeoffHelper();
const selected = ref(null);
const expandedRowGroups = ref([true]);
const todayYear = DateTime.local().year;
const selectedYear = ref(DateTime.local().year);
const nameFilter = ref(null);
const statusFilter = ref([]);
const typeFilter = ref([]);
const statuses = ['approved', 'declined', 'pending'];
const showFilters = ref(false);
const calendarVisible = ref(false);
const holidaysStore = useHolidaysStore();

const attributes = computed(() => {
  const attrs = [];
  attrs.push({key: 'today', dot: true, dates: new Date(), popover: {label: 'Today'}});
  for (const timeOff of entries.value) {
    const toAttr = {
      highlight: {color: to.byValue(timeOff.type).color},
      dates: {start: DateTime.fromISO(timeOff.start), end: DateTime.fromISO(timeOff.end)},
      popover: {
        label: `${timeOff.user.name} - ${timeOff.type}`,
      },
      timeOff: timeOff
    };
    if (timeOff == selected.value) toAttr.highlight.fillMode = 'solid';
    else if (timeOff.partial) toAttr.highlight.fillMode = 'outline';
    else toAttr.highlight.fillMode = 'light';
    attrs.push(toAttr);
  }

  for (const holiday of holidaysStore.holidays) {
    if (!props.location || holiday.location === props.location) {
      attrs.push({
        popover: {label: `${holiday.name} (${holiday.location})`},
        dot: {color: 'red'},
        dates: {start: DateTime.fromISO(holiday.date), end: DateTime.fromISO(holiday.date)},
      });
    }
  }


  return attrs;
});

const years = computed(() => {
  const years = new Set();
  for (const timeOff of props.timeoffList) {
    years.add(DateTime.fromISO(timeOff.start).year);
  }
  return Array.from(years).sort().reverse();
});

const types = computed(() => to.types());

const hasTimeoffs = computed(() => props.timeoffList?.length > 0);

const entries = computed(() => props.timeoffList.filter(matchesFilter));

const currentYear = computed(() => selectedYear.value == todayYear);

const hasFilter = computed(() => nameFilter.value || statusFilter.value.length || typeFilter.value.length);

function matchesFilter(timeoff) {
  if (DateTime.fromISO(timeoff.start).year != selectedYear.value) return false;
  if (nameFilter.value && !timeoff.user.name.toLowerCase().includes(nameFilter.value.toLowerCase())) return false;
  if (statusFilter.value.length && !statusFilter.value.includes(timeoff.status)) return false;
  return !(typeFilter.value.length && !typeFilter.value.map(t => t.value).includes(timeoff.type));
}

function instrumentTimeoffs() {
  if (props.timeoffList) {
    const today = DateTime.local().toISODate();
    props.timeoffList.forEach(t => t.upcoming = t.start >= today);
    expandedRowGroups.value = [props.timeoffList.some(e => e.upcoming)];
  }
}

function selectTimeOff(day) {
  selected.value = to.findByDate(props.timeoffList, day.date);
}

function getApprovalTooltip(timeoff) {
  switch (timeoff.status) {
    case 'declined':
      return `Declined by ${timeoff.approvedBy?.name}`;
    case 'approved':
      return `Approved by ${timeoff.approvedBy?.name}`;
    default:
      return '';
  }
}

function trimComment(comment) {
  return isCommentLong(comment) ? comment.substring(0, COMMENT_CUTOUT) + '...' : comment;
}

function displayDates(timeOff) {
  if (timeOff.start != timeOff.end)
    return `${humanDate(timeOff.start)} - ${humanDate(timeOff.end)}`;
  return humanDate(timeOff.start);
}

function humanDate(date) {
  return DateTime.fromISO(date).toFormat("MMM dd");
}

function isCommentLong(comment) {
  return comment?.length > COMMENT_CUTOUT;
}

const stats = ref();

function showStats(event) {
  stats.value.toggle(event);
}

function clearFilters() {
  nameFilter.value = null;
  statusFilter.value = [];
  typeFilter.value = [];
}

function downloadCSV() {
  let csv = 'user,createdAt,start,end,type,workdays,comment\n';
  entries.value.forEach(timeoff => {
    let comment = timeoff.comment ? timeoff.comment : '';
    comment = comment.replace(/(\r\n|\n|\r)/gm, ' ').replace(/"/g, '""');
    comment = `"${comment}"`;
    csv += `${timeoff.user.name},${timeoff.createdAt},${timeoff.start},${timeoff.end},${timeoff.type},${to.countWorkdays(timeoff)},${comment}\n`;
  });

  let blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
  let url = URL.createObjectURL(blob);
  let link = document.createElement("a");
  if (link.download !== undefined) {
    link.setAttribute("href", url);
    link.setAttribute("download", "timeoffs.csv");
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

const calendar = ref()
watch(selected, () => {
  if (selected.value && calendar.value)
    calendar.value.move(selected.value.start);
});

watch(() => props.timeoffList, () => {
  instrumentTimeoffs();
});

onMounted(() => {
  instrumentTimeoffs();
  emit('mounted');
});
</script>

<template>
  <div>
    <div class="flex flex-row gap-1" v-if="timeoffList">
      <Transition>
        <div id="calendar-panel" v-if="calendarVisible">
          <Calendar :columns="1" :rows="2" :step="1" :attributes="attributes"
                    @dayclick="selectTimeOff"
                    ref="calendar"
                    borderless
                    transparent
                    :is-dark="isDark()"
                    :key="darkKey"
          />
        </div>
      </Transition>

      <div class="flex-col w-full">
        <Panel class="h-fit" pt:header:class="!pb-0">
          <template #header>
            <div class="flex flex-col">
              <div class="flex justify-between mb-2 gap-2">
                <div class="flex gap-2">
                  <span
                      class="-ml-8 -mt-2 cursor-pointer"
                      v-tooltip.top="'Toggle calendar'"

                      @click="calendarVisible = !calendarVisible"
                  >
                    <i class="pi pi-chevron-left text-surface-300 dark:text-surface-600 !text-xl border rounded-full px-1 bg-surface border-surface"
                       :class="calendarVisible ? 'pi pi-chevron-left' : 'pi pi-chevron-right'"
                    />
                  </span>
                  <slot name="toolbar" :data="attributes"/>
                  <Select :options="years" v-model="selectedYear" :disabled="!hasTimeoffs"/>

                    <Button :outlined="!showFilters"
                            severity="secondary"
                            @click="showFilters = !showFilters"
                            v-tooltip.top="'Toggle filters'"
                            :disabled="!hasTimeoffs" :icon="hasFilter ? 'pi pi-filter-fill' : 'pi pi-filter'">
                    </Button>
                  <TimeoffLegend/>

                </div>

              </div>
              <div class="flex gap-2 p-4" v-if="showFilters">
                <MultiSelect :options="statuses" v-model="statusFilter" placeholder="all statuses">
                  <template #value="slotProps">
              <span v-tooltip.top="slotProps.value.join(', ')">
                {{ slotProps.value.length ? 'Status: ' + slotProps.value.length : 'all statuses' }}
              </span>
                  </template>
                  <template #option="slotProps">
                    <TimeoffStatusLabel :status="slotProps.option"/>
                  </template>
                </MultiSelect>
                <MultiSelect :options="types" v-model="typeFilter" placeholder="all types">

                  <template #value="slotProps">
                    <div class="flex items-center" v-if="slotProps.value.length">
                      <span class="mr-1" v-tooltip="slotProps.value">Types: </span>
                      <TimeoffType :value="t.value" :hide-label="true" v-for="t in slotProps.value"/>
                    </div>
                    <div v-else>
                      <span>all types</span>
                    </div>
                  </template>
                  <template #option="slotProps">
                    <TimeoffType :color="slotProps.option.color" :label="slotProps.option.label"/>
                  </template>
                </MultiSelect>
                <InputText v-model="nameFilter" placeholder="Filter by Name" v-if="!hideName"/>
                <Button icon="pi pi-times" v-tooltip.top="'Clear filters'" text rounded severity="secondary"
                        @click="clearFilters"/>
              </div>
            </div>
          </template>
          <template #icons>

            <div>
              <Button @click="downloadCSV" icon="pi pi-download" v-tooltip.top="'Download CSV'" outlined text
                      v-if="exportable"/>
              <Button icon="pi pi-chart-bar" outlined severity="secondary" @click="showStats"
                      v-tooltip.top="'Show stats'"
                      v-if="stats && hasTimeoffs"/>
            </div>
            <Popover ref="stats">
              <TimeOffStats :entries="entries" :year="selectedYear"/>
            </Popover>
          </template>
          <DataTable
              v-if="hasTimeoffs"
              :value="entries"
              data-key="id"
              selection-mode="single"
              v-model:selection="selected"
              sort-mode="single"
              sort-field="start"
              :sort-order="-1"
              :meta-key-selection="true"
              group-rows-by="upcoming"
              :row-group-mode="currentYear ? 'subheader' : ''"
              :expandable-row-groups="currentYear"
              v-model:expanded-row-groups="expandedRowGroups"
              scrollable
              scroll-height="60vh"
              style="min-height: 60vh"
          >
            <template #groupheader="slotProps">
              <span class="p-text-secondary">{{ slotProps.data.upcoming ? 'Upcoming' : 'Past' }}</span>
            </template>
            <Column field="upcoming" v-if="currentYear"/>
            <Column field="user.name" header="User" v-if="!hideName"/>
            <Column field="type" header="" style="width: 1rem">
              <template #body="slotProps">
                <TimeoffType :value="slotProps.data.type" :hide-label="true"/>
              </template>
            </Column>

            <Column field="start" header="Dates" style="width: 15rem">
              <template #body="slotProps">
              <span v-tooltip.top="`Requested on ${humanDate(slotProps.data.createdAt)}`">
                {{ displayDates(slotProps.data) }}
              </span>
              </template>
            </Column>
            <Column header="Days" style="width: 1rem">
              <template #body="slotProps">
                {{ slotProps.data.partial ? slotProps.data.partial / 8 : to.countWorkdays(slotProps.data) }}
              </template>
            </Column>

            <Column header="Comment" v-if="!hideComment">
              <template #body="slotProps">
              <span v-if="isCommentLong(slotProps.data.comment)"
                    v-tooltip.top="slotProps.data.comment">{{ trimComment(slotProps.data.comment) }}</span>
                <span v-else>{{ slotProps.data.comment }}</span>
              </template>
            </Column>

            <Column field="status" header="Status" style="width: 1rem">
              <template #body="slotProps">
                <TimeoffStatusLabel :status="slotProps.data.status" v-tooltip="getApprovalTooltip(slotProps.data)" :key="slotProps.data.status"/>
              </template>
            </Column>
            <Column>
              <template #body="slotProps">
                <slot name="timeoffActions" :data="slotProps.data"/>
              </template>
            </Column>
            <template #expansion="slotProps">
              <p>
          <span v-if="slotProps.data.comment">
            {{ slotProps.data.comment }}
          </span>
                <span v-else class="p-text-secondary italic">(no comment)</span>
              </p>
            </template>
          </DataTable>
          <div v-else>
            <slot name="empty"/>
          </div>
        </Panel>
      </div>
    </div>
    <div v-else>
      <Skeleton width="10rem" class="mb-2"></Skeleton>
    </div>
    <Debug :data="{location, timeoffList}" />
  </div>

</template>
