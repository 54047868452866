<script setup>
import {formatDate} from "@/helpers/date.helper";
import {computed} from "vue";
import useTimeoffHelper from "@/composables/useTimeoffHelper";

const {day} = defineProps(['day'])
const to = useTimeoffHelper()

const decoration = computed(() => {
  switch (day.type) {
    case "OOO":
    case 'HOLIDAY':
      return 'pulse-pill-ooo';
    case "PULSE":
      return 'pulse-pill-pulse';
    case "NO_INFO":
      return 'pulse-pill-noinfo';
    case "FUTURE":
      return 'pulse-pill-future';
    default:
      return 'primary';
  }
})

const tooltip = computed(() => {

  if (!day.date) return ''
  let tooltip = day.date;
  switch (day.type) {
    case "OOO":
      tooltip += `\n${to.byValue(day.text)?.label}`;
      break;
    case "NO_INFO":
    case "HOLIDAY":
      tooltip += `\n${day.text}`;
      break;
  }
  return tooltip;
})

</script>

<template>
  <span class="pulse-pill"
        :class="decoration"
        v-tooltip.top="tooltip"
  >
    <slot>{{formatDate(day.date, 'EEE')}}</slot>
  </span>
</template>
