<script>
import {backend, Toaster} from "@/helpers";
import PeopleSelector from "../../components/PeopleSelector.vue";
import AppPage from "@/components/AppPage.vue";
import Listbox from "primevue/listbox";
import Badge from "primevue/badge";
import InputText from "primevue/inputtext";
import MultiSelect from "primevue/multiselect";
import Button from "primevue/button";
import ConfirmDialog from "primevue/confirmdialog";
import Select from "primevue/select";

export default {
  components: {PeopleSelector, AppPage, Listbox, Badge, InputText, Select, MultiSelect, Button, ConfirmDialog},
  data: () => ({
    groups: [],
    channels: [],
    selected: null,
    errorMsg: "",
    successMsg: "",
    users: [],
    loaded: false
  }),
  async created() {
    this.channels = await backend.getSlackChannels();
    this.users.push(...await backend.usersShort());
    await this.refreshGroups();
  },
  computed: {
    allReviewers() {
      return this.users.filter(u => u.is_reviewer);
    },
    toaster() {
      return new Toaster(this.$toast);
    }
  },
  methods: {
    reviewers(group) {
      return group.reviewers?.map(r => r.name).join(", ");
    },
    async refreshGroups() {
      this.loaded = false;
      this.groups = await backend.groups();
      if (!this.selected && this.groups?.length > 0)
        this.selected = this.groups[0];
      this.loaded = true;
    },

    setReviewers(e) {
      let reviewersText = e.target.value;
      this.selected.reviewers = reviewersText.split(",").map(r => r.trim());
    },
    async saveGroup() {
      try {
        console.log("Saving group ", this.selected);
        const result = await backend.saveGroup(this.selected);
        this.selected.id = result.id; // set id for the case new group is saved
        this.toaster.success("Saved group " + this.selected.name);
      } catch (e) {
        this.errorMsg = e;
        this.toaster.error(e);
      }
    },
    addGroup() {
      let newGroup = {
        name: "<new group>",
        reviewer: "",
        people: [],
        reviewers: []
      };
      this.groups.push(newGroup);
      this.selected = newGroup;
    },
    deleteGroupConfirm() {
      this.$confirm.require({
        message: `Are you sure you want to delete ${this.selected.name} group?`,
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-secondary p-button-outlined',
        accept: this.deleteGroup,
      });
    },
    deleteGroup() {
      try {
        if (this.selected.id) {
          backend.deleteGroup({id: this.selected.id});
          this.toaster.success("Deleted group " + this.selected.name);
        }
        this.groups.splice(this.groups.indexOf(this.selected), 1);
        this.selected = null;
        this.refreshGroups();
      } catch (e) {
        this.errorMsg = e;
        this.toaster.error(e);
      }
    },

    peopleUpdated(people) {
      this.selected.people = people;
    },

    async toggleTeams(teams) {
      this.selected.teams = teams;
      await this.saveGroup();
    },

    async addPeople(people) {
      this.selected.people.push(...people);
      try {
        await backend.addPeopleToGroup({'groupId': this.selected.id, 'peopleIds': people.map(p => p.id)});
        const pplLabel = people.length === 1 ? 'person' : 'people';
        this.toaster.success(`Added ${people.length} ${pplLabel} to ${this.selected.name}`);
      } catch (error) {
        this.toaster.error(error);
      }
    },

    async removePeople(people) {
      this.selected.people = this.selected.people.filter(p => !people.includes(p));
      try {
        await backend.removePeopleFromGroup({'groupId': this.selected.id, 'peopleIds': people.map(p => p.id)});
        const pplLabel = people.length === 1 ? 'person' : 'people';
        this.toaster.success(`Removed ${people.length} ${pplLabel} from ${this.selected.name}`);
      } catch (error) {
        this.toaster.error(error);
      }
    },

    async saveTeamForPeople(people, team) {
      const userIds = people.map(p => p.id);
      try {
        await backend.setTeam({team, userIds});
        this.toaster.success(`Assigned ${userIds.length} users to ${team}`);
      } catch (e) {
        this.toaster.error(e);
      }
      await backend.setTeam({team, userIds});
    },

  }
}
</script>
<template>
  <AppPage title="Groups" :loaded="loaded">
    <ConfirmDialog/>
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-4 pb-0">
        <div class="flex gap-2 items-center">
          <Button @click="addGroup" severity="secondary" rounded outlined icon="pi pi-plus" v-tooltip="'Add group'" />
          <Button @click="deleteGroupConfirm" severity="danger" rounded outlined icon="pi pi-trash" v-tooltip="'Delete group'"
                  :disabled="!selected"/>
        </div>
      </div>
      <div class="col-span-8"></div>
      <div class="col-span-4 flex flex-col">
        <Listbox v-model="selected" option-label="name" :options="groups" class="mb-4 h-full" scrollHeight="100%">
          <template #option="slotProps">
            <div class="flex gap-2 justify-between grow">
              <div class="flex flex-col gap-2">
                <div class="flex gap-2 justify-start">
                  <strong>{{ slotProps.option.name }}</strong>
                </div>
                <div class="flex gap-2">
                  <span class="text-muted-color text-sm">
                     {{ slotProps.option.reviewers?.map(r => r.name).join(", ") }}
                  </span>
                </div>
              </div>
              <div class="flex items-center">
                <Badge :value="slotProps.option.people?.length" severity="secondary"/>
              </div>
            </div>
          </template>
        </Listbox>

      </div>
      <div class="col-span-8">
        <div class="formgrid grid grid-cols-12 gap-4" v-if="selected">

          <div class="field col-span-12 md:col-span-6">
            <label for="reviewer">Name</label>
            <InputText v-model="selected.name" class="w-full"
            @change="saveGroup"/>
          </div>
          <div class="field col-span-12 md:col-span-6">
            <label for="slack">Slack channel for time off requests</label>
            <Select v-model="selected.slackId"
                      :options="channels"
                      optionLabel="name" optionValue="id" class="w-full" showClear
                      @change="saveGroup"/>
          </div>
          <div class="field col-span-12">
            <label for="reviewer">Reviewers</label>
            <MultiSelect v-model="selected.reviewers" :options="allReviewers"
                         display="chip"
                         class="w-full" data-key="id" option-label="name"
                        @change="saveGroup"/>
          </div>
          <div class="field col-span-12">
            <PeopleSelector :people="selected.people" :users="users"
                            @update:people="peopleUpdated"
                            @set:team="saveTeamForPeople"
                            @teams:toggle="toggleTeams"
                            @people:add="addPeople"
                            @people:remove="removePeople"
                            :teams="selected.teams" class="container gy-3"/>
          </div>
        </div>
      </div>
    </div>
  </AppPage>
</template>
