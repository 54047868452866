<script setup lang="ts">

import {computed, onMounted, Ref, ref} from "vue";
import pulseBackend from "@/backend/pulse.backend";
import DayPicker from "@/components/DayPicker.vue";
import {DateTime} from "luxon";
import {useRoute, useRouter} from "vue-router";
import Debug from "@/components/Debug.vue";
import PulseCard from "@/components/pulse/PulseCard.vue";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import InputText from "primevue/inputtext";
import {CompanyPulseData} from "@/types/DataTypes.ts";
import Accordion from 'primevue/accordion';
import AccordionPanel from 'primevue/accordionpanel';
import AccordionHeader from 'primevue/accordionheader';
import AccordionContent from 'primevue/accordioncontent';
import PulseDayStats from "./PulseDayStats.vue";

const router = useRouter();
const route = useRoute();

const loading = ref(false)
const dateStart: Ref<DateTime> = ref<DateTime>(initDate()) // ?
const pulses = ref<CompanyPulseData[] | null>()
const filter = ref()
const statsKey = ref(0);


const filtered = computed(() => {
  if (!pulses.value) return null
  return pulses.value.filter((p) => {
    if (!filter.value) return true
    const term = filter.value.toLowerCase()
    return p.user.name.toLowerCase().includes(term) ||
      p.user.team && p.user.team.toLowerCase().includes(term)
  })
})

const teams = computed(() => {
  if (!pulses.value) return null
  return [...new Set(pulses.value.map((p) => p.user.team))].sort((a, b) => a ? a.localeCompare(b) : 1)
});

function initDate() {
  const baseDate = route.query.date ? DateTime.fromISO(route.query.date as string) : DateTime.now()
  return baseDate.startOf('day')// .startOf('week')
}

async function loadPulses() {
  loading.value = true
  const isoDate = dateStart.value.toISODate(); // ?
  pulses.value = await pulseBackend.date(isoDate!)();

  const hasRecords = pulses.value.map((r) => Object.keys(r.pulses).length).some((x) => x > 0); // Check if there is at least one Pulse to be displayed
  pulses.value = hasRecords ? pulses.value : null;                                             // modify the pulses object for 'template #empty' in DataTable to work

  statsKey.value++;
  loading.value = false
}


onMounted(async () => {
  const dateOf = route.query.date ? DateTime.fromISO(route.query.date as string) : DateTime.now().startOf('day')
  if (dateOf.toISODate() !== dateStart.value.toISODate()) {
    dateStart.value = dateOf
    pulses.value = null
  }

  if (!pulses.value) {
    await loadPulses()
  }
})

async function switchDate() {
  await router.push({query: {date: dateStart.value.toISODate()}})
  await loadPulses();
}

function getOne(pulse: CompanyPulseData) {
  return Object.values(pulse.pulses)[0]
}

function filterByTeam(team: string) {
    return filtered.value?.filter((p) => p.user.team === team)
}
</script>

<template>
  <div class="flex flex-col gap-2">
    <div class="flex items-center gap-2">
      <DayPicker v-model="dateStart" @change="switchDate"/>
      <IconField>
        <InputIcon><i class="pi pi-search"/></InputIcon>
        <InputText v-model="filter" placeholder="Filter Team & Names"/>
      </IconField>
    </div>
    <div class="flex items-center gap-2 pt-4 pb-4">
      <PulseDayStats :pulses="pulses" v-if="pulses" :key="statsKey" />
    </div>
    <Accordion :value="teams" multiple>
        <AccordionPanel v-for="team in teams" :value="team">
          <AccordionHeader>
            {{ team }}
          </AccordionHeader>
          <AccordionContent>

            <div class="grid grid-cols-1 gap-4 2xl:grid-cols-3">
              <PulseCard
                  v-for="pulse in filterByTeam(team)"
                  :pulse="getOne(pulse)"
                  :key="`${pulse.user.id}:${getOne(pulse).date}`"
                  class="hover:z-10 hover:drop-shadow-xl"
              >
                <template #legend>
                  <div class="flex flex-row items-center ">
                    <span class="font-bold"> {{ pulse.user.name }}</span>
                  </div>
                </template>
              </PulseCard>
            </div>
          </AccordionContent>
        </AccordionPanel>
    </Accordion>
    <div>

      <Debug :data="pulses"/>
    </div>
  </div>
</template>
