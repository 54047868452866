<script setup lang="ts">

import {computed} from "vue";
import {DateTime} from "luxon";
import DatePicker from 'primevue/datepicker';

const day = defineModel<DateTime>({required: true})
const emit = defineEmits(['change'])

const date = computed(() => {
  return day.value.toJSDate();
})

type DayPickerProps = {
  isLastDay?: boolean // ?
  today?: DateTime
}
const props = defineProps<DayPickerProps>()

const isToday = computed(() => {
  const today = props.today || DateTime.now()
  return today >= day.value.startOf('day') && today <= day.value.endOf('day') //?
})

function prevDay() {
  day.value = day.value.minus({days: 1})
  emit('change')
}

function nextDay() {
  day.value = day.value.plus({days: 1})
  emit('change')
}

function changeDay(event: Date) {
  day.value = DateTime.fromJSDate(event);
  emit('change')
}

</script>

<template>
  <div class="flex flex-row items-center border border-surface rounded-md place-self-start">
    <Button icon="pi pi-chevron-left" outlined severity="secondary" text @click="prevDay" :disabled="isLastDay"/>
    <DatePicker :modelValue="date" @update:modelValue="changeDay" :maxDate="new Date()" dateFormat="M dd" />
    <Button icon="pi pi-chevron-right" outlined severity="secondary" text @click="nextDay"
            :disabled="isToday"/>
  </div>
</template>
