<script setup lang="ts">
import useTimeoffHelper from "@/composables/useTimeoffHelper.ts";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import TimeoffType from "@/components/timeoff/TimeoffType.vue";
import {computed} from "vue";

const {entries, year} = defineProps(['entries', 'year'])
const to = useTimeoffHelper();

const yearStats = computed(() => {
  return to.types().map(type => {
    return {'label': type.label, 'color': type.color, 'count': to.countAllWorkdays(entries, type.value)}
  }).filter(stat => stat.count > 0);
})

const countAllWorkdays = computed(() => {
  return to.countAllWorkdays(entries);
})

</script>
<template>
  <div>
    <h3>Timeoff in {{ year }} </h3>
    <DataTable :value="yearStats">
      <Column field="label" header="Total">
        <template #body="slotProps">
          <TimeoffType :label="slotProps.data.label" :color="slotProps.data.color"/>
        </template>
      </Column>
      <Column field="count" :header="countAllWorkdays">

      </Column>
    </DataTable>
  </div>
</template>
