import {defineStore} from "pinia";
import {ref} from "vue";
import {Holiday, OfficeLocation} from "@/types/DataTypes.ts";
import {peopleBackend} from "@/backend/people.backend.ts";
import {adminBackend} from "@/backend/admin.backend.ts";

export const useHolidaysStore
    = defineStore('holidays', () => {
    const holidays = ref([] as Holiday[]);

    peopleBackend.holidays().then((data) => {
        holidays.value = data;
    })

    const locations = ref(Object.values(OfficeLocation));

    async function addHolidays(newHolidays: Holiday[]) {
        console.log("Adding holidays", newHolidays);
        holidays.value = await adminBackend.addHolidays(newHolidays);
    }

    function forLocation(location: OfficeLocation) {
        return holidays.value.filter(holiday => holiday.location === location);
    }

    return {
        holidays, locations, addHolidays, forLocation
    }
});