<script setup lang="ts">

import {computed } from "vue";
import PulsePill from "@/components/pulse/PulsePill.vue";

const {pulses} = defineProps(['pulses']);

const stats = computed(() => {
  const stats: { submitted: number, missing: number, ooo: number, total: number, participationRate: number } = {
    submitted: 0,
    missing: 0,
    ooo: 0,
    total: 0,
    participationRate: 0
  }

  for (const pulse of pulses) {
    for (const day of Object.keys(pulse.pulses)) {
      const type = pulse.pulses[day].type
      if (type === 'PULSE') {
        stats.submitted++
      } else if (['NO_INFO', 'TODAY'].includes(type)) {
        stats.missing++
      } else if (type === 'OOO') {
        stats.ooo++
      }
      // SKIP and FUTURE types are not counted in participation stats
    }
  }

  stats.total = stats.submitted + stats.missing + stats.ooo
  stats.participationRate = Math.round((stats.submitted / stats.total) * 100)

  return stats
})

</script>

<template>

  <div class="flex gap-2 items-center">
    <span class="font-bold">Participation:</span>

    <PulsePill :day="{type: ''}">{{ stats.participationRate }}%</PulsePill>
    <PulsePill :day="{type: 'PULSE'}" v-if="stats.submitted" v-tooltip.top="'Submitted'">{{ stats.submitted }}</PulsePill>
    <PulsePill :day="{type: 'NO_INFO'}" v-if="stats.missing" v-tooltip.top="'Missing'">{{ stats.missing }}</PulsePill>
    <PulsePill :day="{type: 'OOO'}" v-if="stats.ooo" v-tooltip.top="'OOO'">{{ stats.ooo }}</PulsePill>
  </div>
  
</template>
